<template>
    <div class="full">
        <div  v-bind:style="{position:'absolute',right:right,top:top}" @click="toggleFullScreen"><i class="el-icon-full-screen"></i></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "FullSceen",
    props: {
        top:{
            type:String,
            default:"50px"
        },
        right:{
            type:String,
            default:"100px"
        }
    },
    data() {
      return {};
    },
    methods:{
        toggleFullScreen(){
            const elem = document.documentElement;
            if (elem.requestFullscreen) {
            elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
            }
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  