<!--
 * @Date: 2023-10-17 15:54:12
 * @LastEditTime: 2023-11-06 15:34:56
 * @Description: 1024-换衣
-->
<template>
  <div>
    <Viewheader theme="light" ref="navheader" />
    <div class="smearCruch">
      <div class="smearCruch_bg"></div>
      <div class="smearCruch-wrap">
        <div class="smearCruch-title">换衣</div>
        <FullSceen :right="'30px'" :top="'20px'" />
        <div class="productMain">
          <div style="height: 100%; flex: 1">
            <div class="changine-product-title">
              <img
                src="../../assets/img/1024/product-icon.png"
                width="25px"
                height="28px"
              />商品图
            </div>
            <div class="changine-product-Main">
              <div
                class="changine-logoExample"
                :class="{ prohibitClicking: prohibitClicking == true }"
              >
                <div
                  class="changine-logoExampleMain"
                  v-for="(item, index) in changineProduct"
                  :key="index"
                  @click="selectProductImg(index, item.img, item.categoryId)"
                  :class="{ imgActive: productImgCurrentActive === index }"
                >
                  <img :src="item.img" />
                </div>
              </div>
            </div>
          </div>
          <div class="changine-product-cont">
            <ImgUpload
              class="sc-img-upload"
              @select="selectPicture"
              @success="getResultImg"
              :imgList="modelList"
              title="模特"
              :titleIcon="modelIcon"
              :modelIndex="imgCurrentActive"
              :tipsShow="true"
              :listScrollTop.sync="listScrollTop"
            />
          </div>
          <div class="changing-btn-wrap">
            <div
              class="changing-btnBox"
              @click="modelDressing"
              :class="{ buttonStatusClick: buttonStatusClick }"
            >
              <!-- <span class="changing-btn"></span> -->
              <img :src="modelBtn" class="changing-btn" />模特穿衣
            </div>
          </div>
          <div class="changing-dressingeffect-wrap">
            <div class="changine-product-title changine-model-title">
              <img
                src="../../assets/img/1024/dressing-icon.png"
                width="25px"
                height="28px"
              />穿衣效果
            </div>
            <div class="changing-dressingeffect-pic-Box">
              <div
                class="downloadIcon"
                v-if="isDownloadIcon"
                @click="submitForm"
              >
                <span class="downloadIconImg"></span>
              </div>
              <div class="changing-dressingeffect-pic-wrap">
                <!-- <div v-if="isShowTip">点击“<a href="javascript:;" @click="modelDressing">模特穿衣</a>”重新尝试</div> -->
                <div class="changing-dressingeffect-pic">
                  <img :src="defaultModelImg" class="dressingeffectImgResult" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Viewheader from '../../components/viewheader/viewheader.vue'
import ImgUpload from '@/components/secondPage/imgUpload1024.vue'
import FullSceen from '../../components/fullSceen/index.vue'

//接口
import { tryOn } from '@/api/tryOn.js'
import { apiUri } from '../../api/txt'

//图片
import DownloadIcon from '../../assets/img/1024/downloadIcon.png'
import DownloadIconHover from '../../assets/img/1024/downloadIcon-hover.png'
import DownloadIconClick from '../../assets/img/1024/downloadIcon-click.png'
import ModelBtn from '../../assets/img/1024/btn-icon.png'
import ClickModelBtn from '../../assets/img/1024/btn-iconCurrent.png'
import ClickModelBtnGif from '../../assets/img/1024/model-arrow.gif'
import ModelIcon from '../../assets/img/1024/model-icon.png'
import Loadding from '../../assets/img/1024/loadd.gif'

// 公用组件
import '../../assets/css/public.css'
import '../../assets/css/imgPublic.css'
export default {
  name: 'smearCruch',
  components: {
    Viewheader,
    ImgUpload,
    FullSceen,
  },
  data() {
    return {
      listScrollTop: false,
      // 商品示例图列表
      changineProduct: [
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/a56155e2-f414-44c0-8af7-218d0b13d6d4.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/20231021-185400(1).jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/14067_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/09569_00_cloth.png',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/08322_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/08079_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/07968_00_cloth.png',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/03814_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/02824_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/02372_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/02278_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/01745_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/00991_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/00163_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/00143_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/00132_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/00003_00.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/tops10.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/tops9.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/tops8.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/tops7.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/tops6.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/tops5.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/tops4.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/tops3.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/tops2.jpg',
          categoryId: 0,
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/tops1.jpg',
          categoryId: 0,
        },
      ],
      // 初始化选中状态
      productImgCurrentActive: -1,
      // 模特示例图列表
      modelList: [
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/1.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/1698122186938/2(1).jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/15010765652/20231021-151600.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model1.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model2.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model3.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model4.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model5.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model6.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model7.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model8.jpg',
        },
      ],
      // 模特初始化选中图
      defaultModelImg:
        'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model1.jpg',
      //记录上一次合成图片
      recordPrevImg:
        'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/model1.jpg',
      // 模特初始化选中状态
      imgCurrentActive: -2,
      // 加载时，禁止点击
      prohibitClicking: false,
      //模特穿衣按钮状态
      buttonStatusClick: false,
      isDownloadIcon: false,
      //提示文案
      isShowTip: false,
      downloadIcon: DownloadIcon,
      downloadIconHover: DownloadIconHover,
      downloadIconClick: DownloadIconClick,
      modelBtn: ModelBtn,
      clickModelBtn: ClickModelBtn,
      modelIcon: ModelIcon,
    }
  },
  mounted() {
    //商品图默认点击高亮
    this.selectProductImg(
      this.productImgCurrentActive,
      this.clothesImgUrl,
      this.categoryId,
    )

    //模特图默认点击高亮
    this.selectPicture(this.imgCurrentActive, this.modelImgUrl)
  },
  methods: {
    //商品图点击选中高亮
    selectProductImg(index, img, categoryId) {
      this.clothesImgUrl = img
      this.productImgCurrentActive = index
      this.categoryId = categoryId
      //设置模特穿衣按钮状态
      this.buttonStatus()
    },
    //模特图点击选中高亮
    selectPicture(index, img) {
      this.modelImgUrl = img
      this.imgCurrentActive = index
      //设置模特穿衣按钮状态
      this.buttonStatus()
    },
    //改变模特穿衣按钮
    changemodelImage() {
      this.modelBtn = ClickModelBtnGif
    },
    //设置模特穿衣按钮状态
    buttonStatus() {
      if (this.productImgCurrentActive >= 0 && this.imgCurrentActive >= 0) {
        this.buttonStatusClick = true
        //this.modelBtn = ClickModelBtn;
        //改变模特穿衣按钮
        this.changemodelImage()
      }
    },
    // 成功回调
    getResultImg(response, file, fileList) {
      this.exampleDiagram.unshift({
        url: response.data,
      })
      this.imgCurrentActive = 0
      this.listScrollTop = true
    },
    //模特穿衣请求
    modelDressing() {
      if (!this.buttonStatusClick) {
        return
      }
      //禁止点击模特穿衣按钮
      this.buttonStatusClick = false
      //改变模特穿衣图片
      this.modelBtn = ModelBtn
      this.recordPrevImg = this.defaultModelImg
      this.defaultModelImg = Loadding
      this.isShowTip = false
      //请求接口
      tryOn({
        categoryId: this.categoryId,
        clothesImgUrl: this.clothesImgUrl,
        modelImgUrl: this.modelImgUrl,
      }).then((res) => {
        const _this = this
        if (res.data.state === '0x0000') {
          const base64 = res.data.data.resultBase64 // 这里是base64字符串
          this.defaultModelImg = 'data:image/jpeg;base64,' + base64
          this.isDownloadIcon = true
          //禁止点击模特穿衣按钮
          this.buttonStatusClick = true
          //改变模特穿衣图片
          this.modelBtn = ClickModelBtnGif
        } else if (res.data.state === '0x0008') {
          this.$message.error(res.data.message)
          this.$refs.navheader.logOn()
          this.defaultModelImg = this.recordPrevImg
          // this.isDownloadIcon = true
          //this.isShowTip = true
        } else {
          //禁止点击模特穿衣按钮
          this.buttonStatusClick = true
          //改变模特穿衣图片
          this.modelBtn = ClickModelBtnGif
          this.$message.error('未识别到“白底正面模特图”')
          this.defaultModelImg = this.recordPrevImg
          //this.isShowTip = true
        }
      })
    },
    submitForm() {
      if (this.isDownloadIcon) {
        const base64 = this.defaultModelImg // 这里是base64字符串
        const blob = this.base64ToBlob(base64)
        this.downloadFile(blob, new Date().getTime() + '.png')
      }
    },
    downloadFile(file, filename) {
      const a = document.createElement('a')
      const url = URL.createObjectURL(file)
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()

      setTimeout(() => {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    },
    //图片Base64 编码转换
    base64ToBlob(base64) {
      const parts = base64.split(';base64,')
      const contentType = parts[0].split(':')[1]
      const raw = window.atob(parts[1])
      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    },
    downloadFile(file, filename) {
      const a = document.createElement('a')
      const url = URL.createObjectURL(file)
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()

      setTimeout(() => {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    },
    // 成功回调
    getResultImg(response, file, fileList) {
      console.log(response)
      // 根据图片设置画板的宽度、高度
      //var canvas = document.getElementById('canvas')
      // 画板大小
      // canvas.width = 0
      // canvas.height = 0
      if (response.state === '0x0000') {
        setTimeout(() => {
          this.modelList.unshift({
            url: response.data.fileUrl,
          })
          this.imgCurrentActive = 0
          this.listScrollTop = true

          this.imgCurrentActive = -2
          this.buttonStatusClick = false
          this.modelBtn = ModelBtn

          // 成功图
          this.successImg = true
          this.loadImg = false
          this.errImg = false

          this.imageName = ''
          // 获取原始图片宽高
          // this.$nextTick(() => {
          var imgb = new Image()
          imgb.src = response.data
          imgb.onload = () => {
            // 原始高度
            this.widtha = imgb.width
            // console.log(this.widtha)

            this.heighta = imgb.height
            // console.log(this.heighta)

            this.canvas()
          }

          // 图片队列
          this.cleanImgList = []
          this.cleanImgList.push({ img: response.data })
          // })
        }, 1000)
      } else if (response.state === '0x0008') {
        this.$message.error(response.message)
        this.$refs.navheader.logOn()
      } else {
        setTimeout(() => {
          // 加载时禁止点击切换
          this.prohibitClicking = true
          // 成功图
          this.successImg = false
          // 失败图
          this.errImg = true
          this.errText = response.message
          this.loadImg = false
          // 图片队列
          this.cleanImgList = []
        }, 1000)
      }
    },
  },
}
</script>

<style scoped>
.sc-img-upload {
  flex-basis: 130px;
  height: 100%;
  margin-right: 39px;
}
.sc-img-upload .img-upload {
  width: 100%;
  overflow: hidden;
}
.smearCruch {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: -80px;
  height: 100vh;
  overflow: hidden;
  padding: 100px 80px 40px 80px;
  box-sizing: border-box;
}
.smearCruch_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 0, 41, 1);
}
/* .smearCruch_bg img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
} */
.smearCruch_bg::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 44%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, #100029 0%, rgba(16, 0, 41, 0) 100%);
}
.smearCruch_bg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 56%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg, #000, rgba(16, 0, 41, 0));
}

.smearCruch-wrap {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
  margin: auto;
  padding: 24px 40px 0px 40px;
  background: linear-gradient(
    112deg,
    rgba(255, 255, 255, 0.64) 18%,
    rgba(255, 255, 255, 0.48) 80%
  );
  /* background: linear-gradient(112deg, #a3a3a3 18%, #7a7a7a 80%); */
  border-radius: 16px;
  color: #000;
  /* margin-top: 100px; */
  font-size: 16px;
  box-sizing: border-box;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.smearCruch-title {
  display: flex;
  font-size: 24px;
  color: #6c38e0;
  justify-content: space-between;
}
.productMain {
  display: flex;
  align-items: flex-start;
  height: calc(100% - 57px);
  flex-direction: row;
  overflow: hidden;
}
.changine-product-Main {
  /* width:820px;  */
  height: calc(100% - 70px);
  margin-top: 12px;
  margin-bottom: 15px;
  display: flex;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 10px; /* 设置滚动条宽度 */
}
.logoUpload {
  width: 158px;
  height: 157px;
}

.logoUpload .el-upload--picture-card {
  width: 158px;
  height: 157px;
  line-height: 157px;
}
.changine-logoExample {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  grid-column-gap: 12px;
  align-content: flex-start;
}
.changine-logoExampleMain {
  width: 251px;
  height: 251px;
  line-height: 157px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}

.changine-logoExampleMain img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}

.changine-product-title {
  margin-top: 24px;
  font-size: 20px;
  font-weight: bold;
}
.changine-product-title img {
  vertical-align: middle;
}
.changine-model-title {
  margin-bottom: 12px;
}
.changine-product-cont {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  height: calc(100% - 70px);
}
.changing-btn-wrap {
  transition-duration: 0.25s 1s;

  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}
.changing-btnBox {
  width: 124px;
  height: 48px;
  margin-left: -15px;
  margin-right: 24px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  color: #98989e;
  background: #c5c5cb;
  transition-duration: 0.25s 1s;
}
.changing-btn {
  width: 28px;
  height: 28px;
  margin-top: -5px;
  margin-right: 4px;
  vertical-align: middle;
  /* display: inline-block;
  background: url('../../assets/img/1024/btn-icon.png') no-repeat;
  background-size:28px 28px; */
}
.buttonStatusClick .changing-btn {
  background: url('../../assets/img/1024/model-arrow.gif') no-repeat;
  background-size: 28px 28px;
}
.changing-dressingeffect-pic-Box {
  width: 100%;
  height: calc(100% - 74px);
  background-color: #fff;
  border-radius: 12px;
  padding-bottom: 3px;
  position: relative;
}
.changing-dressingeffect-pic-wrap {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.changing-dressingeffect-pic-wrap a {
  color: #6c38e0;
}
.changing-dressingeffect-pic {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.dressingeffectImgResult {
  position: absolute;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
}
.changing-dressingeffect-wrap {
  max-width: 896px;
  width: 570px;
  height: 100%;
}
.buttonStatusClick {
  background: #6c38e0;
  color: #fff;
}
.downloadIcon {
  text-align: right;
}
.downloadIconImg {
  margin: 16px 16px 0px 0px;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../../assets/img/1024/downloadIcon.png') no-repeat;
  background-size: 20px 20px;
}
.downloadIconImg:hover {
  background: url('../../assets/img/1024/downloadIcon-hover.png') no-repeat;
  background-size: 20px 20px;
}
.downloadIconImg:active {
  background: url('../../assets/img/1024/downloadIcon-click.png') no-repeat;
  background-size: 20px 20px;
}
.downloadIcon img {
  width: 20px;
  height: 20px;
  margin-top: 16px;
  margin-right: 16px;
  cursor: pointer;
}

@media screen and (max-width: 3840px) {
  /* .changine-product-Main {
    width:1100px;
  }
  .changing-dressingeffect-wrap{
   width: 35vw;
  } */
}
</style>
