import request from "@/router/axios";
// 试衣
export const tryOn = (data) => {
  return request({
   url: "/api/img/tryOn",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token:localStorage.getItem("tokenKey")?localStorage.getItem("tokenKey"):''
    },
    data: {
      ...data,
    },
  });
};

